import { graphql } from "gatsby"
import React from "react"

interface Props {
  event: {
    title: string
    location: string
    date: string
    link: string
  }
}

export const Event = ({ event }: Props) => {
  return (
    <div className="column is-one-third">
      <a href={event.link} className="box event is-special">
        <article>
          <h1 className="is-size-4 has-text-weight-bold">{event.title}</h1>
          <div className="py-2">{event.location}</div>
          <div className="pb-2">{event.date}</div>
        </article>
      </a>
    </div>
  )
}
