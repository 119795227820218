import { graphql, Link } from "gatsby"
import * as React from "react"
import Layout from "../components/layout/Layout"
import { Event } from "../components/community/Event"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { getEvents } from "../components/utils/Event"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
        communitySupport: Array<{
          title: string
          description: string
          url: string
        }>
        projects: Array<{
          title: string
          description: string
          url: string
        }>
        twitters: Array<{
          title: string
          description: string
        }>
        youtubeInfo: {
          title: string
          description: string
        }
      }
    }
    events: {
      nodes: Array<{
        title: string
        date: string
        location: string
        link: string
      }>
    }
  }
}

const Support = ({ item }: any) => {
  return (
    <article className="py-2">
      <h1>
        <a className="link" href={item.url}>
          {item.title}
        </a>
      </h1>
      <p>{item.description}</p>
    </article>
  )
}

const Project = ({ item }: any) => {
  return (
    <article className="py-2 column is-6">
      <h1>
        <Link to={item.url}>{item.title}</Link>
      </h1>
      <p>{item.description}</p>
    </article>
  )
}

const Twitter = ({ item }: any) => {
  return (
    <article>
      <a href={`https://twitter.com/${item.description}`}>{item.title}</a>
    </article>
  )
}

const YoutubeEmbed = ({ embedId }: any) => (
  <iframe
    width="100%"
    height="300"
    src={`https://www.youtube.com/embed/videoseries?list=${embedId}`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Spring"
  />
)

// markup
const CommunityTemplate = (props: Props) => {
  const events = getEvents(props.data.events.nodes as any).filter((item, i) => i < 6)
  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className="community"
    >
      <div className="hero-community has-background-light pb-6">
        <div className="extra-0">
          <div className="extra-1">
            <img src="/img/extra/community.svg" alt="Community" />
          </div>
        </div>
        <div className="container pt-4">
          <h1 className="title has-text-weight-bold mt-6 mb-5 pt-6">
            Community
          </h1>
          <div className="events">
            <h2 className="is-size-4 has-text-weight-bold mb-5 pt-5">Events</h2>
            <div className="columns is-space-between is-multiline">
              {events.map((event, i) => (
                <Event key={`event${i}`} event={event} />
              ))}
            </div>
            <div className="buttons is-centered">
              <Link className="button is-center is-spring" to={`/events`}>
                More events
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-5 mt-6">
        <div className="mb-6 pb-6">
          <div className="columns">
            <div className="column is-4">
              <h2 className="has-big-border-dark-top is-size-4 has-text-weight-bold mb-5 pt-5">
                Community support
              </h2>
              <div className="event links-animate">
                {props.data.page.frontmatter.communitySupport.map(
                  (item: any, index: number) => (
                    <Support item={item} key={`support-${index}`} />
                  )
                )}
              </div>
            </div>
            <div className="community-projects column pl-6 is-8">
              <h2 className="has-big-border-dark-top is-size-4 has-text-weight-bold mb-5 pt-5">
                Community projects
              </h2>
              <div className="columns is-multiline links-animate">
                {props.data.page.frontmatter.projects.map(
                  (project: any, index: number) => (
                    <Project item={project} key={`project-${index}`} />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="pb-6 mb-6">
          <div className="columns">
            <div className="column is-4">
              <h2 className="has-big-border-dark-top is-size-4 has-text-weight-bold mb-5 pt-5">
                Twitter
              </h2>
              <div className="twitter socials pt-2">
                {props.data.page.frontmatter.twitters.map(
                  (twitter: any, index: number) => (
                    <Twitter item={twitter} key={`twitter-${index}`} />
                  )
                )}
              </div>
            </div>
            <div className="column socials pl-6 is-8">
              <h2 className="has-big-border-dark-top is-size-4 has-text-weight-bold mb-5 pt-5">
                YouTube
              </h2>
              <div className="pt-2 socials">
                <div className="youtube">
                  <YoutubeEmbed
                    embedId={props.data.page.frontmatter.youtubeInfo.title}
                  />
                </div>
                <div className="pt-5">
                  <a
                    href={props.data.page.frontmatter.youtubeInfo.description}
                    className="button is-spring"
                  >
                    More videos
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default CommunityTemplate

// graphQL queries
export const pageQuery = graphql`
  query CommunityTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        communitySupport {
          title
          description
          url
        }
        projects {
          title
          description
          url
        }
        twitters {
          title
          description
        }
        youtubeInfo {
          title
          description
        }
      }
    }
    events: allContentfulEvent(sort: { order: ASC, fields: date }) {
      nodes {
        title
        link
        date(formatString: "MMMM DD, YYYY")
        location
      }
    }
  }
`
